<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="20">
        <el-button type="primary"
                   @click="toAdd">
          新增
        </el-button>

      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <addForm ref="addFormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <EditForm ref="EditFormRef" />
  </div>
</template>

<script>
import addForm from './components/index.vue'
import EditForm from './components/editForm.vue'
import { sheetUrl } from '@/utils/basicUrl.js'
export default {
  data () {
    return {
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      showList: [],
      tableLoading: false,
      pageName: 'cz_testingCentre_admin',
      tableColumns: [
        { prop: 'edu_paper_id', align: 'center', label: 'ID', width: '100', showToopic: false },
        { prop: 'paper_name', align: 'center', label: '试卷名称', width: '', showToopic: true },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        // { prop: 'paper_district_desc', align: 'center', label: '试卷地区', width: '', showToopic: false },
        // { prop: 'check_point_desc', align: 'center', label: '知识点名称', width: '', showToopic: false, },
        { prop: 'paper_score', align: 'center', label: '总分', width: '100', showToopic: false },
        { prop: 'knowledge', align: 'center', label: '考点', width: '250', showToopic: false, isStatus: true },
        { prop: 'paper_time', align: 'center', label: '开始时间', width: '', showToopic: false, sortable: true },
        { prop: 'end_time', align: 'center', label: '截止时间', width: '', showToopic: false, sortable: true },
        // { prop: 'show_to_desc', align: 'center', label: '可见性', width: '', showToopic: false, },
        { prop: 'paper_enable', align: 'center', label: '是否提交', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '250', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '制作答题卡', type: 'export', event: 'exportCard' },
            { content: '面批', event: 'toCorrectPaper', isBtnStatus: true },
            { content: '收藏', event: 'collect', type: "collect", isChangeType: true },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    addForm, EditForm
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value, row) {
      if (prop == 'knowledge') {
        return 'shenlue'
      }
      return value ? 'pass' : 'notpass'
    },
    getValue (prop, value, row) {
      if (prop == 'knowledge') {
        return row.knowledge.length ? row.knowledge.map(item => item).join(',') : ''
      }
      if (prop == 'paper_enable') {
        return value ? '已提交' : '未提交'
      }
    },
    getIconContent (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return '收藏'
        } else {
          return '取消收藏'
        }
      }
    },
    getIconType (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return 'collect'
        } else {
          return 'cancelCollect'
        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    collect (row) {
      this.$http({
        url: '/api/v1/combination/collect',
        method: 'post',
        data: {
          edu_paper_id: row.edu_paper_id
        }
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    toAdd () {
      let form = {
        paper_name: '',
        paper_time: '',
        paper_duration: '',
        knowledge_id: [],
        end_time: '',
        semester: 2,
        question_num: 10,
        difficulty: '',
        source_id: 1,
        question_year: '',
        paper_district: '',
        edu_paper_type: '',
        given_garde: '',
        given_class: '',
        subjectId: '',
      }
      this.$refs.addFormRef.form = form
      this.$refs.addFormRef.getList()
      this.$refs.addFormRef.dialogVisible = true
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v1/combination/knowledge',
        method: 'get',
        params: {
          page, limit
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    toInfo (row) {
      this.$router.push('/testPapercomposition/testingCentre/info?edu_paper_id=' + row.edu_paper_id)
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/combination/del_edu_paper',
          method: 'post',
          data: {
            edu_paper_id: row.edu_paper_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    editRow (row) {
      this.$http({
        url: '/api/v1/combination/getPaperInfoAndRelation',
        method: 'get',
        params: {
          id: row.edu_paper_id
        }
      }).then(res => {
        this.$refs.EditFormRef.getPaperTypeList(res.data.info)
        this.$refs.EditFormRef.dialogVisible = true
      })
    },
    // exportCard (row) {
    //   this.$router.push(`/testPapercomposition/makeSheet?id=${row.edu_paper_id}&sheet_id=${row.sheet_id}&status=${row.sheet_status}`)
    // },
    exportCard (row) {
      //this.$router.push(`/testPapercomposition/makeSheet?id=${row.edu_paper_id}&sheet_id=${row.sheet_id}&status=${row.sheet_status}&correct_type=0`)
       let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
       window.open(`${sheetUrl}/#/?czb=1&paper_id=${row.edu_paper_id}&cannot_edit=1&t=${userInfo.token}&rt=${userInfo.refresh_token}`, '_blank')
    },
    //面批
    toCorrectPaper (row) {
      this.$router.push('/correctPaperCard?edu_paper_id=' + row.edu_paper_id)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>